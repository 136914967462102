<script setup lang="ts">
onMounted(() => {
	window?.$crisp?.push(["safe", true])
})
</script>
<template>
  <div class="font-['Manrope']">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ModalManager />
  </div>
</template>
