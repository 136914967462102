<script lang="ts" setup>
type TProps = {
	to?: string | undefined,
	ghost?: boolean | undefined,
	link?: boolean | undefined,
}
const props = withDefaults(defineProps<TProps>(), {})

const classes = computed(() => {
	const classes = [
		'rounded-full px-4 py-1 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 transition-all duration-300 ease-in-out'
	]

	if (props.ghost || props.link) {
		if (props.link) {
			classes.push('bg-transparent text-primary-600 underline')
		} else {
			classes.push('bg-transparent text-primary-600 hover:bg-primary-500/10 hover:text-primary-700 bg-blend-soft-light bg-blur-2xl')
		}
	} else {
		classes.push('bg-primary-600 text-secondary-500 hover:bg-primary-500 shadow-sm disabled:bg-gray-400 disabled:cursor-not-allowed disabled:text-gray-300')
	}

	return classes
})
</script>
<template>
	<NuxtLink :class="classes" v-if="to" :to="to" v-bind="$attrs">
		<slot />
	</NuxtLink>
	<button :class="classes" v-else v-bind="$attrs">
		<slot />
	</button>
</template>
