/*
* This file contains the page configuration for the application.
* Here you can set the sections that will be displayed on each page.
* This sections will be displayed in the order they are defined.
*/

export default {
	// The sections that will be displayed on the index page.
	index: {
		_sections: [
			{ id: 'newsletters', component: 'NewsletterCards' },
		]
	}
}
