<script setup lang="ts">

</script>
<template>
	<div
		class="fixed z-50 inset-x-0 bottom-0 mx-auto w-full flex md:flex-col justify-center items-center bg-primary-300 text-white md:py-3 py-2">
		<div class="text-xs">
			<NuxtLink to="https://squinbox.com" class="flex flex-col md:flex-row items-center justify-center gap-x-4 gap-y-2 text-center">
				<span>Esta landing page esta creada con</span>
				<div class="w-28 -ml-2">
					<img src="/images/brand/logo-squinbox.png">
				</div>
				<span class="hidden md:block">Si quieres tener tu propia landing page, visita <NuxtLink to="https://squinbox.com">squinbox.com</NuxtLink></span>
			</NuxtLink>
		</div>
	</div>
</template>
