import { defineNuxtPlugin } from '#imports'
      import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
      import { resetCount } from '@formkit/core'

      import importedConfig from '/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/formkit.config.ts'

      export default defineNuxtPlugin((nuxtApp) => {
        const config = defaultConfig(typeof importedConfig === 'function' ? importedConfig() : importedConfig)
        nuxtApp.hook('app:rendered', (renderContext) => {
          resetCount()
          ssrComplete(nuxtApp.vueApp)
        })
        nuxtApp.vueApp.use(plugin, config)

      })
      