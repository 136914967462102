export default {
	items: [
		{
			name: "Feature number 1",
			description: "This is the feature number one",
			icon: "",
		},
		{
			name: "Feature number 2",
			description: "This is the feature number two",
			icon: "",
		},
		{
			name: "Feature number 3",
			description: "This is the feature number three",
			icon: "",
		},
		{
			name: "Feature number 4",
			description: "This is the feature number four",
			icon: "",
		},
		{
			name: "Feature number 1",
			description: "This is the feature number one",
			icon: "",
		},
		{
			name: "Feature number 2",
			description: "This is the feature number two",
			icon: "",
		},
		{
			name: "Feature number 3",
			description: "This is the feature number three",
			icon: "",
		},
		{
			name: "Feature number 4",
			description: "This is the feature number four",
			icon: "",
		},
		
	],
}
