
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "16b8a3ec-e544-4da7-be85-e2e988afddc9"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/app.config.ts"
import cfg1 from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
