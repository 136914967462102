import { default as _indexRHIdAjsc1KMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/_index.vue?macro=true";
import { default as _91_46_46_46slug_93oqJyGZePR3Meta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/[...slug].vue?macro=true";
import { default as loginFzHIkoZbq0Meta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/login.vue?macro=true";
import { default as registerlbE3f1hmSzMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/register.vue?macro=true";
import { default as verify_45requestKjjaODEfQyMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/verify-request.vue?macro=true";
import { default as indexPj6wwa0kgUMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/checkout/index.vue?macro=true";
import { default as successC7QQ0fhkbbMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/checkout/success.vue?macro=true";
import { default as indexaw6aWSYcUUMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/index.vue?macro=true";
import { default as profileBDPiE5A9pdMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/profile.vue?macro=true";
import { default as settingsFvgxUF9xIcMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/settings.vue?macro=true";
import { default as dashboardt8HrYhZloBMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard.vue?macro=true";
import { default as privacy_45policyJMhv4pucrtMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/legal/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionsKtHNCxb5IQMeta } from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/legal/terms-and-conditions.vue?macro=true";
import { default as _91slug_93TZzH4OBmMhMeta } from "/vercel/path0/pages/n/[slug].vue?macro=true";
import { default as demo4eDiyYBQFEMeta } from "/vercel/path0/pages/n/demo.vue?macro=true";
import { default as _91slug_93HlS8cZblqXMeta } from "/vercel/path0/pages/newsletters/[slug].vue?macro=true";
import { default as indexqkIdbLut82Meta } from "/vercel/path0/pages/newsletters/index.vue?macro=true";
export default [
  {
    name: _indexRHIdAjsc1KMeta?.name ?? "_index",
    path: _indexRHIdAjsc1KMeta?.path ?? "/_index",
    meta: _indexRHIdAjsc1KMeta || {},
    alias: _indexRHIdAjsc1KMeta?.alias || [],
    redirect: _indexRHIdAjsc1KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/_index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oqJyGZePR3Meta?.name ?? "slug",
    path: _91_46_46_46slug_93oqJyGZePR3Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93oqJyGZePR3Meta || {},
    alias: _91_46_46_46slug_93oqJyGZePR3Meta?.alias || [],
    redirect: _91_46_46_46slug_93oqJyGZePR3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: loginFzHIkoZbq0Meta?.name ?? "auth-login",
    path: loginFzHIkoZbq0Meta?.path ?? "/auth/login",
    meta: loginFzHIkoZbq0Meta || {},
    alias: loginFzHIkoZbq0Meta?.alias || [],
    redirect: loginFzHIkoZbq0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registerlbE3f1hmSzMeta?.name ?? "auth-register",
    path: registerlbE3f1hmSzMeta?.path ?? "/auth/register",
    meta: registerlbE3f1hmSzMeta || {},
    alias: registerlbE3f1hmSzMeta?.alias || [],
    redirect: registerlbE3f1hmSzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: verify_45requestKjjaODEfQyMeta?.name ?? "auth-verify-request",
    path: verify_45requestKjjaODEfQyMeta?.path ?? "/auth/verify-request",
    meta: verify_45requestKjjaODEfQyMeta || {},
    alias: verify_45requestKjjaODEfQyMeta?.alias || [],
    redirect: verify_45requestKjjaODEfQyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/auth/verify-request.vue").then(m => m.default || m)
  },
  {
    name: indexPj6wwa0kgUMeta?.name ?? "checkout",
    path: indexPj6wwa0kgUMeta?.path ?? "/checkout",
    meta: indexPj6wwa0kgUMeta || {},
    alias: indexPj6wwa0kgUMeta?.alias || [],
    redirect: indexPj6wwa0kgUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: successC7QQ0fhkbbMeta?.name ?? "checkout-success",
    path: successC7QQ0fhkbbMeta?.path ?? "/checkout/success",
    meta: successC7QQ0fhkbbMeta || {},
    alias: successC7QQ0fhkbbMeta?.alias || [],
    redirect: successC7QQ0fhkbbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    path: dashboardt8HrYhZloBMeta?.path ?? "/dashboard",
    children: [
  {
    name: indexaw6aWSYcUUMeta?.name ?? "dashboard",
    path: indexaw6aWSYcUUMeta?.path ?? "",
    meta: indexaw6aWSYcUUMeta || {},
    alias: indexaw6aWSYcUUMeta?.alias || [],
    redirect: indexaw6aWSYcUUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: profileBDPiE5A9pdMeta?.name ?? "dashboard-profile",
    path: profileBDPiE5A9pdMeta?.path ?? "profile",
    meta: profileBDPiE5A9pdMeta || {},
    alias: profileBDPiE5A9pdMeta?.alias || [],
    redirect: profileBDPiE5A9pdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsFvgxUF9xIcMeta?.name ?? "dashboard-settings",
    path: settingsFvgxUF9xIcMeta?.path ?? "settings",
    meta: settingsFvgxUF9xIcMeta || {},
    alias: settingsFvgxUF9xIcMeta?.alias || [],
    redirect: settingsFvgxUF9xIcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard/settings.vue").then(m => m.default || m)
  }
],
    name: dashboardt8HrYhZloBMeta?.name ?? undefined,
    meta: dashboardt8HrYhZloBMeta || {},
    alias: dashboardt8HrYhZloBMeta?.alias || [],
    redirect: dashboardt8HrYhZloBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJMhv4pucrtMeta?.name ?? "legal-privacy-policy",
    path: privacy_45policyJMhv4pucrtMeta?.path ?? "/legal/privacy-policy",
    meta: privacy_45policyJMhv4pucrtMeta || {},
    alias: privacy_45policyJMhv4pucrtMeta?.alias || [],
    redirect: privacy_45policyJMhv4pucrtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/legal/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsKtHNCxb5IQMeta?.name ?? "legal-terms-and-conditions",
    path: terms_45and_45conditionsKtHNCxb5IQMeta?.path ?? "/legal/terms-and-conditions",
    meta: terms_45and_45conditionsKtHNCxb5IQMeta || {},
    alias: terms_45and_45conditionsKtHNCxb5IQMeta?.alias || [],
    redirect: terms_45and_45conditionsKtHNCxb5IQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/pages/legal/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TZzH4OBmMhMeta?.name ?? "n-slug",
    path: _91slug_93TZzH4OBmMhMeta?.path ?? "/n/:slug()",
    meta: _91slug_93TZzH4OBmMhMeta || {},
    alias: _91slug_93TZzH4OBmMhMeta?.alias || [],
    redirect: _91slug_93TZzH4OBmMhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/n/[slug].vue").then(m => m.default || m)
  },
  {
    name: demo4eDiyYBQFEMeta?.name ?? "n-demo",
    path: demo4eDiyYBQFEMeta?.path ?? "/n/demo",
    meta: demo4eDiyYBQFEMeta || {},
    alias: demo4eDiyYBQFEMeta?.alias || [],
    redirect: demo4eDiyYBQFEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/n/demo.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HlS8cZblqXMeta?.name ?? "newsletters-slug",
    path: _91slug_93HlS8cZblqXMeta?.path ?? "/newsletters/:slug()",
    meta: _91slug_93HlS8cZblqXMeta || {},
    alias: _91slug_93HlS8cZblqXMeta?.alias || [],
    redirect: _91slug_93HlS8cZblqXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/newsletters/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqkIdbLut82Meta?.name ?? "newsletters",
    path: indexqkIdbLut82Meta?.path ?? "/newsletters",
    meta: indexqkIdbLut82Meta || {},
    alias: indexqkIdbLut82Meta?.alias || [],
    redirect: indexqkIdbLut82Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/newsletters/index.vue").then(m => m.default || m)
  }
]