/*
 * This file contains the brand information for the application.
 * Here you can set the name, slogan, logo, etc. for your brand.
 * This information is used in the header and footer components.
 */

export default {
	// The name of your company or application.
	name: "DirectorioNewsletters",
	// The slogan of your company or application.
	slogan: "¡Impulsa tu newsletter con nosotros!",
	// Whether to show the brand in the header and footer.
	show: true,
	// The logo to use of your company or application.
	logo: "/images/brand/logo.png",
}
