import type { Component } from 'vue'

interface IModal {
    id?: string
    component: any | string | Component
    wrapper?: any
    props?: object
    onConfirm?: () => void
    onCancel?: () => void
}


const modals = ref<IModal[]>([])

export const useModal = () => {
    const add = (modal: IModal) => {
        const id = `${ (new Date()).getTime() }-${ Math.random().toString(36).substring(2, 9) }`
        const defaults = {
            id,
            open: true,
            wrapper: 'ModalBase'
        }
        modals.value.push({ ...defaults, ...modal })

        return id
    }

    const remove = (id: any) => {
        modals.value = modals.value.filter((x: any) => x.id !== id)
    }

    const close = (id: any) => {
        modals.value = modals.value.map((x: any) => {
            if (x.id === id) x.open = false
            return x
        })
    }

    const open = (id: any) => {
        modals.value = modals.value.map((x: any) => {
            if (x.id === id) x.open = true
            return x
        })
    }

    const toggle = (id: any) => {
        modals.value = modals.value.map((x: any) => {
            if (x.id === id) x.open = !x.open
            return x
        })
    }

    return {
        modals,
        open,
        close,
        toggle,
        add,
        remove
    }
}
