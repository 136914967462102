<script  lang="ts" setup>
const { modals, close } = useModal()
</script>
<template>
  <component
      v-for="modal in modals"
      :key="modal.id"
      :is="modal.wrapper"
      :id="modal.id"
      :open="modal.open"
      @close="close"
  >
    <component :is="modal.component" :id="modal.id" v-bind="modal.props" @close="close(modal.id)"/>
  </component>
</template>
