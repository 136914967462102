import revive_payload_client_Mr238KQile from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XAMn2bIGmU from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vC7JJvwrhD from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9JJs6nzXdL from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yXytq3Hbb3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_2iQFkcgE5o from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.2.2_vue@3.4.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_lSYcay3uOC from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_3dFO4wL2SD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import plugin_bx8XYYN8FT from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.0_next-auth@4.21.1_vue@3.4.10/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_t194kAkEgw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+plausible@0.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import chunk_reload_client_WCa8DVwYqv from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@types+node@18.18.9_eslint@8.53.0_sass@1.69.5_typescript@5.2.2_vite@5.0.11_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import crisp_chat_client_Xmiy7JC8ge from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/crisp-chat.client.ts";
import event_bus_client_N1Ma2QTxoG from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/event-bus.client.ts";
import featurebase_client_FjQ2mH3w93 from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/featurebase.client.ts";
import modals_QGjCQLDBhS from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/modals.ts";
import praisehive_client_F4gfaQ4klh from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/praisehive.client.ts";
import reflio_client_7L2rPeb2H6 from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/reflio.client.ts";
import sentry_client_Hj9Lxia6JT from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/sentry.client.ts";
import tawk_messenger_client_4y4agMhssQ from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/tawk-messenger.client.ts";
import vue_toastification_client_CdIJ89o8Gz from "/vercel/path0/node_modules/.pnpm/@rapidlaunch-it+nuxt-rapidlaunch@1.8.31_focus-trap@7.5.4_next@13.5.6_nuxt@3.9.1_react-dom@18._imqujgybt4amp7jch6prhnwc3q/node_modules/@rapidlaunch-it/nuxt-rapidlaunch/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_Mr238KQile,
  unhead_XAMn2bIGmU,
  router_vC7JJvwrhD,
  payload_client_9JJs6nzXdL,
  check_outdated_build_client_yXytq3Hbb3,
  plugin_vue3_2iQFkcgE5o,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lSYcay3uOC,
  plugin_client_3dFO4wL2SD,
  formkitPlugin_pZqjah0RUG,
  plugin_bx8XYYN8FT,
  plugin_client_t194kAkEgw,
  chunk_reload_client_WCa8DVwYqv,
  crisp_chat_client_Xmiy7JC8ge,
  event_bus_client_N1Ma2QTxoG,
  featurebase_client_FjQ2mH3w93,
  modals_QGjCQLDBhS,
  praisehive_client_F4gfaQ4klh,
  reflio_client_7L2rPeb2H6,
  sentry_client_Hj9Lxia6JT,
  tawk_messenger_client_4y4agMhssQ,
  vue_toastification_client_CdIJ89o8Gz
]