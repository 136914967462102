export const meta =  [
	{ name: 'apple-mobile-web-app-capable', content: 'yes' },
	{ name: 'msapplication-TileImage', content: '/favicons/mstile-144x144.png' },
	{ name: 'msapplication-config', content: '/favicons/browserconfig.xml' },
]

export const link =  [
	{ rel: 'apple-touch-icon', sizes: '57x57', href: '/favicons/apple-touch-icon-57x57.png' },
	{ rel: 'apple-touch-icon', sizes: '60x60', href: '/favicons/apple-touch-icon-60x60.png' },
	{ rel: 'apple-touch-icon', sizes: '72x72', href: '/favicons/apple-touch-icon-72x72.png' },
	{ rel: 'apple-touch-icon', sizes: '76x76', href: '/favicons/apple-touch-icon-76x76.png' },
	{ rel: 'apple-touch-icon', sizes: '114x114', href: '/favicons/apple-touch-icon-114x114.png' },
	{ rel: 'apple-touch-icon', sizes: '120x120', href: '/favicons/apple-touch-icon-120x120.png' },
	{ rel: 'apple-touch-icon', sizes: '144x144', href: '/favicons/apple-touch-icon-144x144.png' },
	{ rel: 'apple-touch-icon', sizes: '152x152', href: '/favicons/apple-touch-icon-152x152.png' },
	{ rel: 'apple-touch-icon', sizes: '167x167', href: '/favicons/apple-touch-icon-167x167.png' },
	{ rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/apple-touch-icon-180x180.png' },
	{ rel: 'apple-touch-icon', sizes: '1024x1024', href: '/favicons/apple-touch-icon-1024x1024.png' },
	{ rel: 'icon', type: 'image/x-icon', href: '/favicons/favicon.ico' },
	{ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/favicon-16x16.png' },
	{ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/favicon-32x32.png' },
	{ rel: 'icon', type: 'image/png', sizes: '48x48', href: '/favicons/favicon-48x48.png' },
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-640x1136.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-1136x640.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-750x1334.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-1334x750.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1125x2436.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2436x1125.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1170x2532.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2532x1170.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-828x1792.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-1792x828.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1242x2688.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2688x1242.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1242x2208.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2208x1242.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1284x2778.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2778x1284.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1536x2048.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2048x1536.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1620x2160.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2160x1620.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1668x2388.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2388x1668.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-1668x2224.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2224x1668.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
		href: '/favicons/apple-touch-startup-image-2048x2732.png'
	},
	{
		rel: 'apple-touch-startup-image',
		media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
		href: '/favicons/apple-touch-startup-image-2732x2048.png'
	},
	{ rel: 'manifest', href: '/favicons/manifest.webmanifest' },
	{ rel: 'yandex-tableau-widget', href: '/favicons/yandex-browser-manifest.json' }
]
export default { meta, link }
