export default {
	header: {
		changelog: false,
		portal: true,

		navigation: {
			links: [{ name: "Todas las Newsletters", href: "/#top" }],
		},
	},
	auth: () => ({
		// logout: { name: 'Logout' },
		// login: { name: 'Login', href: '/auth/login' },
		// register: { name: 'Register', href: '/auth/register' }
	}),
	
	footer: {
		navigation: {
			links: [
				{ name: "Todas las Newsletters", href: "/#top" },
			],
			legal: [
				{ name: "Privacy Policy", href: "/" },
				{ name: "Terms & Conditions", href: "/" },
			],
			social: [
				{
					name: "Twitter",
					href: "",
					icon: "simple-icons:x",
				},
				{
					name: "Github",
					href: "",
					icon: "simple-icons:github",
				},
			],
		},
	},
}
